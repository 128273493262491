import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from './modules/auth.js';
import ApiModule from './modules/api.js';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    api: ApiModule,
  },
  state: {
    storeBusy: [],
    errMsgs: [],
    hideLeftNav: true,
  },
  getters: {
    isStoreBusy(state) {
      return state.storeBusy.length > 0;
    },
  },
  mutations: {
    APPENDBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index === -1) {
        state.storeBusy.push(payload);
      }
    },
    CLEARBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index !== -1) {
        state.storeBusy.splice(index, 1);
      }
    },
    APPENDERRORMSG(state, errMsg) {
      state.errMsgs.push(errMsg);
    },
    CLEARERRORMSG(state) {
      state.errMsgs.splice(0, state.errMsgs.length);
    },
    SETNAVHIDE(state, payload) {
      state.hideLeftNav = payload;
    },
  },
  actions: {
    appendComponentBusy(context, busyName) {
      context.commit("APPENDBUSY", busyName);
    },
    clearComponentBusy(context, busyName) {
      context.commit("CLEARBUSY", busyName);
    },
    showMsg(context, msg) {
      alert(msg);
    },
    appendErrorMsg(context, errMsg) {
      context.commit("APPENDERRORMSG", errMsg);
    },
  },
})
