import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    TOKENCOOKIEKEY: 'jico_token',
    token: '',
    user: null,
  },
  getters: {
    hasToken(state) {
      return state.token.length > 0;
    },
    hasCookie(state) {
      return Boolean(Vue.$cookies.get(state.TOKENCOOKIEKEY));
    },
    userToken(state) {
      return state.token;
    },
    userData(state) {
      return state.user;
    },
  },
  mutations: {
    UPDATETOKEN(state, token) {
      state.token = token;
      Vue.$cookies.set(state.TOKENCOOKIEKEY, token, "30d");
    },
    CLEARTOKEN(state) {
      state.token = '';
      Vue.$cookies.remove(state.TOKENCOOKIEKEY);
      state.user = null;
    },
    UPDATEUSER(state, user) {
      if (user === null) {
        state.user = null;
      } else {
        state.user = Object.assign({}, state.user, user);
      }
    },
  },
  actions: {
    loggedIn(context, token) {
      context.commit('UPDATETOKEN', token);
    },
    loggedOut(context) {
      context.commit('CLEARTOKEN');
    },
    async initLoginToken(context) {
      const savedToken = Vue.$cookies.get(context.state.TOKENCOOKIEKEY);
      // console.log(`get cookie: ${savedToken}`);
      if (savedToken !== null) {
        try {
          const newToken = await context.dispatch('api/postRefreshTokenPromise', savedToken, {root: true});
          await context.dispatch('loggedIn', newToken.token);

          const infoRes = await context.dispatch('api/getAccountInfoPromise', null, {root:true});
          await context.dispatch('updateUser', infoRes);
          
        } catch(e) {
          console.error(e);
          context.dispatch('loggedOut');
        }
      }
    },
    updateUser(context, user) {
      context.commit('UPDATEUSER', user);
    },
  }

}
