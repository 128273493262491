import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "homeView" */ '../views/HomeView.vue')
  },
  {
    path: '/project/:serial',
    redirect: 'project/:serial/history',
    name: 'ProjectIndex',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "ProjectIndex" */ '../views/project/ProjectIndex.vue'),
    children: [
      {
        path: 'history',
        name: 'ProjectHistory',
        component: () => import(/* webpackChunkName: "ProjectHistory" */ '../views/project/ProjectHistory.vue'),
      },
      {
        path: 'relation',
        name: 'ProjectRelation',
        component: () => import(/* webpackChunkName: "ProjectRelation" */ '../views/project/ProjectRelation.vue'),
      },
      {
        path: 'level',
        name: 'ProjectLevel',
        component: () => import(/* webpackChunkName: "ProjectLevel" */ '../views/project/ProjectLevel.vue'),
      },
      {
        path: 'dictionary',
        name: 'ProjectDictionary',
        component: () => import(/* webpackChunkName: "ProjectDictionary" */ '../views/project/ProjectDictionary.vue'),
      },
      {
        path: 'challenge',
        name: 'ProjectChallenge',
        component: () => import(/* webpackChunkName: "ProjectChallenge" */ '../views/project/ProjectChallenge.vue'),
      },
      {
        path: 'resourceMap',
        name: 'ProjectResourceMap',
        component: () => import(/* webpackChunkName: "ProjectResourceMap" */ '../views/project/ProjectResourceMap.vue'),
      },
      {
        path: 'interview',
        name: 'ProjectInterview',
        component: () => import(/* webpackChunkName: "ProjectInterview" */ '../views/project/ProjectInterview.vue'),
      },
      {
        path: 'category',
        name: 'ProjectCategory',
        component: () => import(/* webpackChunkName: "ProjectCategory" */ '../views/project/ProjectCategory.vue'),
      },
      {
        path: 'mujun',
        name: 'ProjectMujun',
        component: () => import(/* webpackChunkName: "ProjectMujun" */ '../views/project/ProjectMujun.vue'),
      },
      {
        path: 'insight',
        name: 'ProjectInsight',
        component: () => import(/* webpackChunkName: "ProjectInsight" */ '../views/project/ProjectInsight.vue'),
      },
      {
        path: 'question',
        name: 'ProjectQuestion',
        component: () => import(/* webpackChunkName: "ProjectQuestion" */ '../views/project/ProjectQuestion.vue'),
      },
      {
        path: 'persona',
        name: 'ProjectPersona',
        component: () => import(/* webpackChunkName: "ProjectPersona" */ '../views/project/ProjectPersona.vue'),
      },
    ]
  },
  
  {
    path: '/login',
    name: 'Login',
    meta: {
      hideNav: true,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async(to, from, next) => {
  // console.log(`From ${from.name} to ${to.name}`);
  // console.log(`requiresAuth: ` +  to.matched.some((record) => record.meta.requiresAuth))
  // console.log(store.getters['auth/hasToken'])
  // await store.dispatch('pageData/setPageFrom', from.name)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/hasToken']) {
      next();
    } else if(store.getters['auth/hasCookie']) {
      try {
        await store.dispatch('auth/initLoginToken');
        next();
      } catch (error) {
        console.error(error);
        next({ name: 'Login' });
      }
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

router.afterEach(async(to) => {
  // console.log(`${JSON.stringify(to.meta)}`);
  let hideNav = false;
  if (to.meta.hideNav) {
    hideNav = true;
  }
  store.commit('SETNAVHIDE', hideNav);
});

export default router
