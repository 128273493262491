import axios from 'axios';

const API_BASE_URL = '/api';

export default {
  namespaced: true,
  state: {
    URLS: {
      LOGIN: `${API_BASE_URL}/user/login`,
      REFRESH_TOKEN: `${API_BASE_URL}/user/refresh`,
			GET_ACCOUNT_INFO: `${API_BASE_URL}/user/me`,

      GET_PROJECT_LIST: `${API_BASE_URL}/project/list`,
      POST_PROJECT_CREATE: `${API_BASE_URL}/project/create`,
      POST_PROJECT_DELETE: `${API_BASE_URL}/project/delete`,
      GET_PROJECT_DETAIL: `${API_BASE_URL}/project/detail`,
      POST_PROJECT_UPDATE: `${API_BASE_URL}/project/update`,


    }
  },
  getters: {
    axiosConfig(state, getters, rootState) {
      return {
        headers: {
          'token': rootState.auth.token,
          'Cache-Control': 'no-cache',
          // 'Content-Type': 'application/json',
        }
      };
    },
  },
  mutations: {

  },
  actions: {
    postApiPromise({getters}, {url, data}) {
      return new Promise(function(resolve, reject) {
        const postData = data? data: {};
        axios.post(url, postData, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postUploadFilePromise({getters}, {url, filename, file}) {
      const formData = new FormData();
      formData.append(filename, file);
      // const axiosConfig = {
      //   headers: {
      //     'token': getters.axiosConfig.headers.token,
      //     'Cache-Control': 'no-cache',
      //     'Content-Type': 'multipart/form-data',
      //   }
      // };
      const axiosConfig = JSON.parse(JSON.stringify(getters.axiosConfig)); //使用深拷貝, 使其不影響其他引用getters.axiosConfig的未知錯誤
      axiosConfig.headers['Content-Type'] = 'multipart/form-data';
      return new Promise(function(resolve, reject) {
        axios.post(url, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    getApiPromise({getters}, {url}) {
      return new Promise(function(resolve, reject) {
        axios.get(url, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },

    postLoginPromise({dispatch, state}, {email, password}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LOGIN,
        data: {email, password}
      });
    },

    postRefreshTokenPromise({dispatch, state}, token) {
      return dispatch('postApiPromise', {
        url: state.URLS.REFRESH_TOKEN,
        data: {token}
      });
    },

    getAccountInfoPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_ACCOUNT_INFO,
      });
    },


    /* project */
    getProjectListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_PROJECT_LIST,
      });
    },

    postProjectCreatePromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_PROJECT_CREATE,
        data,
      });
    },

    postProjectDeletePromise({dispatch, state}, serial) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_PROJECT_DELETE,
        data: {
          serial
        },
      });
    },

    postProjectUpdatePromise({dispatch, state}, obj) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_PROJECT_UPDATE,
        data: obj,
      });
    },

    getProjectDetailPromise({dispatch, state}, serial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GET_PROJECT_DETAIL}/${serial}`,
      });
    },

    
  },
}
