<template>
  <div id="app" v-if="init">
    <div class="one-page">

      <div class="content">
        <router-view/>
      </div>
    </div>
    <Transition name="fade">
      <div class="loading-mask" v-if="isStoreBusy"></div>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import moment from 'moment';
moment.locale('zh-tw');

export default {
  name: 'AppView',
  data() {
    return {
      init: false,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch('auth/initLoginToken');
      this.init = true;
    } catch(e) {
      console.error(e);
    }
  },
  components: {
  },
  watch: {
  },
  computed: {
    ...mapGetters(['isStoreBusy', 'auth/hasToken']),
    ...mapState(['hideLeftNav']),
    isLoggedIn() {
      return this['auth/hasToken'];
    }
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async loggedOut() {
      await this.$store.dispatch('auth/loggedOut');
      await this.$router.push({name: 'Login'});
    },
  }
}
</script>

<style>
* {
  color: #4d4d4d;
}
</style>

<style lang="scss">
// .one-page {
//   width: 100%;
//   height: 100vh;
//   height: 100dvh;
//   display: flex;
//   nav {
//     // display: flex;
//     flex: 0 0 200px;
//     // flex-direction: column;
//     background-color: #ddd;
//     a {
//       display: block;
//       width: 100%;
//       padding: 1rem 2rem;
//       color: #000;
//     }
//   }

//   .content {
//     flex: 1 1;
//     height: 100vh;
//     height: 100dvh;
//     overflow-x: hidden;
//     overflow-y: auto;
//   }
// }

.content {
  min-height: 100vh;
  background-color: #F7F7F7;
}

.loading-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0005;
  opacity: 1;
  z-index: 99999;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}


</style>
